import Container from "../components/Container";
import Text from "../components/Text";

function NotFoundPage() {
	return (
		<Container>
			<Text>NotFound</Text>
		</Container>
	);
}

export default NotFoundPage;
